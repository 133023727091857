.pricing-charts-header {
  min-height: 280px;
  background-image: linear-gradient(90deg, #000 0%, #1d8674 140%);

  section {
    padding-top: 150px;

    @media(max-width: 767px) {
      padding-top: 90px;
    }

    h3 {
      color: #fff;
      font-size: 29px;
      font-weight: 400;
      text-align: center;

      @media(max-width: 767px) {
        font-size: 27px;
      }
    }

    p {
      text-align: center;
      margin-top: 15px;
      font-size: 16px;
      color: #EFF7FF;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }
  }
}

.pricing-charts-tabs {
  margin-top: 55px;
  text-align: center;

  .tabs {
    margin: 0 auto;
    width: 330px;
  }

  .tab {
    display: inline-block;
    width: 165px;
    float: left;
    border: 1px solid #c7c7c7;
    padding: 13px 0;
    background: #f9f9f9;
    color: #8F8B8B;
    cursor: pointer;
    position: relative;

    @include transition(color .2s linear);

    &:hover {
      color: #222;
    }

    &.active {
      background: #fff;
      color: #222;
      font-weight: 600;
      cursor: default;

      &:hover {
        color: #222;
      }
    }

    &.monthly {
      border-radius: 45px 0 0 45px;

      span {
        position: relative;
        left: 5px;
      }
    }

    &.annual {
      border-left: 0;
      border-radius: 0 45px 45px 0;

      span {
        position: relative;
        left: -5px;
      }
    }

    .discount {
      position: absolute;
      top: -10px;
      font-size: 13px;
    }
  }
}

.pricing-charts {
  margin-top: 60px;

  .chart {
    border: 1px solid #d6d6d6;
    width: 300px;
    display: inline-block;
    border-radius: 3px;
    margin: 0 10px;

    @media(max-width: 991px) {
      width: 240px;
      margin: 0 5px;
    }

    @media(max-width: 767px) {
      display: block;
      width: 290px;
      margin: 0 auto 30px;
    }

    > header {
      background: #f8f8f8;
      border-bottom: 1px solid #d6d6d6;
      padding: 25px 0 17px;
      height: 185px;
      position: relative;

      @media(max-width: 991px) {
        height: 175px;
      }

      .name {
        font-size: 15px;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        color: #555;
        letter-spacing: 6.3px;
        font-family: $myriad;
      }

      .price {
        padding-top: 5px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        opacity: 0;

        @include transition(all .25s linear);

        &.monthly {
          @include transform(translateY(25px));

          &.active {
            opacity: 1;
            @include transform(translateY(0px));
          }

          &.go-out {
            opacity: 0;
            @include transform(translateY(25px));
          }
        }

        &.annually {
          @include transform(translateY(-25px));

          &.active {
            opacity: 1;
            @include transform(translateY(0px));
          }

          &.go-out {
            opacity: 0;
            @include transform(translateY(-25px));
          }
        }

        .currency {
          font-size: 23px;
          position: relative;
          top: -32px;
          color: #444;
        }

        .qty {
          font-size: 80px;
          font-weight: 300;

          @media(max-width: 991px) {
            font-size: 75px;
          }
        }

        .period {
          font-size: 19px;
          color: #555;
          position: relative;
          top: -1px;
        }
      }
    }

    .features {
      min-height: 320px;
      padding: 25px 0;

      .feature {
        text-align: center;
        font-size: 16px;
        padding: 8px 0;
        color: #555;

        @media(max-width: 991px) {
          font-size: 15px;
        }
      }
    }

    .action {
      text-align: center;
      padding: 15px 0 27px;
    }
  }
}

.pricing-charts-faqs {
  margin-top: 120px;
  background: #fafafa;
  border-top: 1px solid #e2e1e1;
  border-bottom: 1px solid #e2e1e1;
  padding: 45px 0 40px;

  .container {
    @media (min-width: 1200px) {
      width: 980px;
    }
  }

  .faq-icon {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    background: #d8fbd9;
    border-radius: 100%;
    position: relative;

    i {
      position: absolute;
      color: #1bce40;
      font-size: 26px;
      left: 18px;
      top: 8px;
    }
  }

  h3 {
    text-align: center;
    font-size: 24px;
    color: #444;
    margin-bottom: 70px;
    margin-top: 40px;
  }

  .faq {
    margin-bottom: 45px;
    padding-left: 30px;

    @media(max-width: 767px) {
      text-align: center;
      padding-left: 15px;
    }

    strong {
      font-size: 17px;
      font-weight: 500;
    }

    p {
      margin-top: 17px;
      color: #666;
      font-size: 15px;
      line-height: 24px;
      width: 85%;

      @media(max-width: 767px) {
        width: auto;
      }
    }
  }
}

.pricing-charts-doubts {
  margin-top: 80px;
  margin-bottom: 130px;

  h3 {
    text-align: center;
    font-size: 20px;
    color: #555;
    margin-bottom: 50px;
    text-transform: uppercase;
    letter-spacing: 3px;
  }

  .alts {
    text-align: center;

    a {
      display: inline-block;
      margin: 0 35px;
      color: #6eaaea;
      font-size: 17px;
      text-decoration: none;

      @media(max-width: 991px) {
        display: block;
        margin-bottom: 20px;
      }

      .fa-twitter {
        font-size: 26px;
        position: relative;
        top: 2px;
        margin-right: 7px;
      }

      .ion-email {
        font-size: 27px;
        position: relative;
        top: 4px;
        margin-right: 9px;
      }

      .fa-phone {
        font-size: 26px;
        position: relative;
        top: 3px;
        margin-right: 7px;
      }
    } 
  }
}
